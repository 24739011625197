import { useInvestmentsData } from '../queries/protocols';
import { useModalsStore } from '../stores/modals';
import { useProtocolsStore } from '../stores/protocols';
import ProtocolsComponent from './ProtocolsComponent';
import ApiErrorComponent from './notifications/ApiErrorComponent';
import LoadingComponent from './notifications/LoadingComponent';
import NoDataFound from './notifications/NoDataFound';
import React from 'react';

function ProtocolsComponentDialog() {
  const { showModalProtocolsMobile, setShowModalProtocolsMobile } =
    useModalsStore((state) => ({
      showModalProtocolsMobile: state.showModalProtocolsMobile,
      setShowModalProtocolsMobile: state.setShowModalProtocolsMobile,
    }));

  const { skip, limit } = useProtocolsStore((state) => ({
    skip: state.skip,
    limit: state.limit,
  }));

  const {
    data: strategies,
    isLoading: isLoadingInvestments,
    isError: isErrorProtocols,
    error: errorProtocols,
    refetch: refetchProtocols,
  } = useInvestmentsData(skip, limit);

  React.useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setShowModalProtocolsMobile(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <>
      {showModalProtocolsMobile ? (
        <>
          <div
            className="fixed inset-0 z-[60] justify-center xl:hidden overflow-x-hidden overflow-y-auto outline-none flex bg-background focus:outline-none"
            onClick={(e) => {
              if (e.target === e.currentTarget)
                setShowModalProtocolsMobile(false);
            }}
          >
            {/* Protocols Table */}
            {isLoadingInvestments ? (
              <LoadingComponent />
            ) : isErrorProtocols ? (
              <ApiErrorComponent
                error={errorProtocols?.toString()}
                refetch={refetchProtocols}
              />
            ) : strategies.strategies.count > 0 ? (
              <ProtocolsComponent items={strategies?.strategies?.items} />
            ) : (
              <NoDataFound message="No protocols found." />
            )}
          </div>
        </>
      ) : null}
    </>
  );
}

export default ProtocolsComponentDialog;
