import * as React from 'react';

interface Props {
  title?: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  options: Array<{ label: string; value: string }> | undefined;
  className: string;
}

export const DropdownComponent: React.FC<Props> = ({
  title,
  value,
  onChange,
  options,
  className,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const selectedOption = options?.find((option) => option.value === value);
  const selectedLabel = selectedOption?.label;

  return (
    <div className="relative group z-[999] w-auto" tabIndex={0}>
      <button
        className={className}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="truncate">
          {title} {selectedLabel}
        </div>
        <svg
          aria-hidden="true"
          className="w-4 h-4 ml-1"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
        </svg>
      </button>
      <nav
        id="dropdown-nav"
        className={`absolute z-[999] left-0  transition-all bg-white rounded invisible dropdown-custom w-inherit top-full group-focus-within:visible group-focus-within:opacity-100 group-focus-within:translate-y-1 max-h-[250px] scrollbar overflow-y-auto`}
        tabIndex={0}
      >
        <ul className="py-1">
          {options?.map((option) => (
            <li
              key={option.value}
              onClick={() => {
                onChange(option.value);
              }}
              className="cursor-pointer"
            >
              <a className="block px-4 py-2 m-2 hover:bg-[#4E4964] hover:rounded-lg font-bold text-white">
                {option.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
