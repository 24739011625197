import { useHistoricalStore } from '../stores/historical';
import { useModalsStore } from '../stores/modals';
import { GoogleAnalytics } from '../utils/GoogleAnalytics';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function SelectStartEndDateDialog() {
  const { showModalDatePicker, setShowModalDatePicker } = useModalsStore(
    (state) => ({
      showModalDatePicker: state.showModalDatePicker,
      setShowModalDatePicker: state.setShowModalDatePicker,
    })
  );

  const {
    startDate: startDateStore,
    setStartDate,
    setEndDate,
    setGranularity,
  } = useHistoricalStore((state) => ({
    startDate: state.startDate,
    setStartDate: state.setStartDate,
    setEndDate: state.setEndDate,
    setGranularity: state.setGranularity,
  }));

  const [startInput, setStartInput] = React.useState(startDateStore);
  const [endInput, setEndInput] = React.useState('');

  React.useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setShowModalDatePicker(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const untilNowSelected = endInput === '';

  return (
    <>
      {showModalDatePicker ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
            onClick={(e) => {
              if (e.target === e.currentTarget) setShowModalDatePicker(false);
            }}
          >
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full border-0 rounded-lg shadow-2xl outline-none container-background-dialog focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold text-white">
                    Select Start and End Date
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => setShowModalDatePicker(false)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">
                  <div className="my-4 text-lg leading-relaxed text-body">
                    <div className="flex flex-col">
                      <div className="text-white text-1xl">Start Date</div>

                      <DatePicker
                        selected={new Date(startInput)}
                        onChange={(date) =>
                          date &&
                          setStartInput(date.toISOString().split('T')[0])
                        }
                        className="p-2 text-black bg-gray-400 border-2 rounded-md"
                      />
                      <div className="mt-4 text-white text-1xl">End Date</div>
                      <div className="flex">
                        <input
                          type="checkbox"
                          defaultChecked
                          onClick={() => {
                            if (untilNowSelected) {
                              setEndInput(
                                new Date().toISOString().split('T')[0]
                              );
                            } else {
                              setEndInput('');
                            }
                          }}
                          className="p-2 mb-1 border-2 rounded-md"
                        />
                        &nbsp;Until now
                      </div>
                      <DatePicker
                        disabled={untilNowSelected}
                        selected={
                          untilNowSelected ? new Date() : new Date(endInput)
                        }
                        className={
                          untilNowSelected
                            ? 'border-2 p-2 rounded-md opacity-50 cursor-not-allowed '
                            : 'border-2 p-2 rounded-md text-black bg-gray-400'
                        }
                        onChange={(date) =>
                          date && setEndInput(date.toISOString().split('T')[0])
                        }
                      />
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t rounded-b">
                  <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModalDatePicker(false)}
                  >
                    Close
                  </button>
                  <button
                    className="px-6 py-3 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded outline-none focus:outline-none"
                    type="button"
                    onClick={() => {
                      setStartDate(startInput);
                      setEndDate(endInput);
                      setShowModalDatePicker(false);
                      setGranularity('custom');
                      GoogleAnalytics.granularityClicked(
                        `clicked '${startInput}' to '${endInput}'`
                      );
                    }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="fixed inset-0 z-40 bg-black opacity-50"
            onClick={() => setShowModalDatePicker(false)}
          ></div>
        </>
      ) : null}
    </>
  );
}

export default SelectStartEndDateDialog;
