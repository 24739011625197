import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';

async function subscribeNewsletter(ctx: QueryFunctionContext) {
  const [, email] = ctx.queryKey;

  if (!email) return null;
  const { data } = await axios.post(
    `https://svaz36lzc6.execute-api.us-east-1.amazonaws.com/post-mailchimp`,
    {
      email,
    }
  );
  return data;
}

export function useSubscribeNewsletter(email: string | undefined) {
  return useQuery(['subscribeNewsletter', email], subscribeNewsletter);
}
