import { useProtocolsStore } from '../../stores/protocols';
import * as React from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchProtocolComponent = () => {
  const setSearch = useProtocolsStore((state) => state.setSearch);
  return (
    <div className="w-full flex flex-row mt-[6px] relative">
      <input
        className="w-full h-[44px] text-[14px] rounded-[44px] bg-input-background text-[#C3BFD9] pl-[16px] pr-[16px] font-medium absolute"
        placeholder="Search for a protocol..."
        type="text"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <FaSearch size={16} color="white" className="absolute right-5 top-3" />
    </div>
  );
};

export default SearchProtocolComponent;
