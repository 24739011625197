import api from '../../services/api';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

async function requestAccess(ctx: QueryFunctionContext) {
  const [, text] = ctx.queryKey;
  if (text) {
    const { data } = await api.post(`/fake-door?email=${text}`);
    return data;
  } else {
    return null;
  }
}

export function useRequestAccess(text: string | undefined) {
  return useQuery(['requestAccess', text], requestAccess);
}
