import { ArrowPathIcon } from '@heroicons/react/24/solid';
import * as React from 'react';

type Props = {
  message?: string;
  inline?: boolean;
};

const LoadingComponent: React.FC<Props> = ({
  message = 'Loading...',
  inline = false,
}) => {
  return (
    <div
      className={`${
        inline ? 'flex-row gap-4' : 'flex-col'
      } flex items-center justify-center mt-4 text-white`}
    >
      <ArrowPathIcon className="w-12 h-12 animate-spin" />
      <div className="text-1xl">{message}</div>
    </div>
  );
};

export default LoadingComponent;
