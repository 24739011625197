import { useInvestmentsData } from '../queries/protocols';
import { Strategy } from '../queries/protocols/types';
import { useModalsStore } from '../stores/modals';
import { useProtocolsStore } from '../stores/protocols';
import { GoogleAnalytics } from '../utils/GoogleAnalytics';
import { PostHogUtils } from '../utils/PostHog';
import FilterByBaseAssetComponent from './filter/FilterByBaseAssetComponent';
import SearchProtocolComponent from './filter/SearchProtocolComponent';
import Image from 'next/image';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';

type Header = {
  key: string;
  label: string;
  onClick: () => void;
};

type Props = {
  items: Strategy[];
};

const ProtocolsComponent: React.FC<Props> = ({ items }) => {
  const [sortColumn, setSortColumn] = React.useState(''); // Current sort column
  const [sortDirection, setSortDirection] = React.useState(''); // Current sort direction

  const {
    search,
    baseAssetFilter,
    selectedStrategyIds,
    skip,
    limit,
    setStrategiesId,
    unsetStrategiesId,
    isProtocolsOpen,
    setIsProtocolsOpen,
    unselectAll,
  } = useProtocolsStore((state) => ({
    search: state.search,
    baseAssetFilter: state.baseAssetFilter,
    selectedStrategyIds: state.selectedStrategyIds,
    skip: state.skip,
    limit: state.limit,
    setStrategiesId: state.setStrategiesId,
    unsetStrategiesId: state.unsetStrategiesId,
    isProtocolsOpen: state.isProtocolsOpen,
    setIsProtocolsOpen: state.setIsProtocolsOpen,
    unselectAll: state.unselectAll,
  }));

  const { setShowModalProtocolsMobile } = useModalsStore((state) => ({
    setShowModalProtocolsMobile: state.setShowModalProtocolsMobile,
  }));

  const { data: strategies } = useInvestmentsData(skip, limit);
  const posthogInstance = usePostHog();

  const filteredItems = items
    ?.filter((item) => {
      if (search === '') {
        return true;
      }
      const searchLowerCase = search.toLowerCase();
      if (
        item.company.toLowerCase().includes(searchLowerCase) ||
        item.network.toLowerCase().includes(searchLowerCase) ||
        item.product.toLowerCase().includes(searchLowerCase) ||
        item.pool.toLowerCase().includes(searchLowerCase)
      ) {
        return true;
      }
      return false;
    })
    .filter((item) => {
      if (baseAssetFilter === '') {
        return true;
      }
      if (item.pool.toLowerCase() === baseAssetFilter.toLowerCase()) {
        return true;
      }
      return false;
    });
  const baseAssetImages =
    strategies &&
    strategies.tokens.reduce(function (map: Record<string, string>, obj) {
      map[obj.address.toLowerCase()] = obj.imageUrl;
      return map;
    }, {});

  //
  // Table Filter
  //
  const headers: Array<Header> = [
    { key: 'pool', label: 'BASE ASSET', onClick: () => handleSort('pool') },
    { key: 'company', label: 'PROTOCOL', onClick: () => handleSort('company') },
    { key: 'network', label: 'NETWORK', onClick: () => handleSort('network') },
    { key: 'product', label: 'PRODUCT', onClick: () => handleSort('product') },
  ];

  const handleSort = (column: string) => {
    // If the same column is clicked again, reverse the sort direction
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending order
      setSortColumn(column);
      setSortDirection('asc');
    }
  };
  // Apply sorting to the filteredItems array based on the current sortColumn and sortDirection
  const sortedItems = React.useMemo(() => {
    if (sortColumn && sortDirection) {
      const compareFn = (a: Strategy, b: Strategy) => {
        const valueA = a[sortColumn as keyof Strategy] ?? '';
        const valueB = b[sortColumn as keyof Strategy] ?? '';
        if (valueA < valueB) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      };
      return filteredItems.slice().sort(compareFn);
    }
    return filteredItems;
  }, [sortColumn, sortDirection, filteredItems]);

  return (
    <>
      {/*  */}
      {/* Protocols */}
      {/*  */}
      {/* md:h-auto w-full flex flex-col md:top-0  */}
      <div
        className={`${
          isProtocolsOpen ? '' : 'xl:w-[450px] '
        }  w-full flex flex-col top-0 xl:bg-transparent md:p-0 xl:mt-0 p-[16px] mt-[26px] `}
      >
        {/* align elements to the end */}
        <div className="w-full flex flex-row justify-end pl-[15px] pr-3">
          <Image
            src="/assets/close_protocols.svg"
            alt="close protocols"
            width={26}
            height={26}
            className="cursor-pointer xl:hidden"
            onClick={() => setShowModalProtocolsMobile(false)}
          />
        </div>
        <div className="w-full flex flex-row md:justify-center pl-[15px] pr-3">
          <div
            className={`${
              isProtocolsOpen ? 'md:justify-between  ' : 'md:justify-start '
            } justify-start flex md:w-[1000px] md:flex-row md:items-center`}
          >
            <div className="text-[25px] font-bold text-white">Protocols</div>
            {isProtocolsOpen && (
              <div
                className="text-[16px] text-title hidden xl:block cursor-pointer"
                onClick={() => setIsProtocolsOpen(false)}
              >
                {'<'} Hide
              </div>
            )}
          </div>
        </div>
        {/* Description */}
        <div className="w-full flex flex-row justify-center mt-[14px] pl-[15px]">
          <div className="w-[1000px] flex flex-col">
            <div className="text-[14px] font-medium text-[#C3BFD9]">
              {!isProtocolsOpen
                ? 'Select below the strategies '
                : 'Select below the strategies you want to compare realized returns of'}
            </div>
          </div>
        </div>
        {/* Protocols */}
        <div className="w-full h-[598px] xl:h-[594px] top-0 z-[60] xl:z-10 left-0 container-background rounded-[16px] mt-[17px] p-[16px] flex flex-col">
          {/* filters */}
          <div className="flex flex-row mt-[4px]">
            <FilterByBaseAssetComponent />
            {selectedStrategyIds.length > 0 && (
              <div className="w-full flex flex-row justify-end text-[12px] font-bold text-[#AF9AFF] mt-[22px] ">
                <div
                  onClick={() => {
                    unselectAll();
                    PostHogUtils.unselectedAllStrategies(posthogInstance);
                  }}
                  className="cursor-pointer hover:underline"
                >
                  {' '}
                  Clear selections
                </div>
              </div>
            )}
          </div>
          {/*  */}
          {/* search for protocols */}
          {/*  */}
          <SearchProtocolComponent />

          {/* Table Header */}
          <div className="grid grid-cols-4 mt-[52px] w-full">
            {/* TableHead */}
            <div className="col-start-1 col-end-5 grid grid-cols-4 pl-[15px] rounded-[8px] h-[35px] text-[#D7D3EA] text-[10px] md:text-[14px] items-center bg-[#5c5776] w-full">
              {headers.map((header, index) => {
                return (
                  <div
                    className={`text-center  flex flex-row items-center cursor-pointer `}
                    key={`protocol-item-table-header-${index}`}
                    onClick={() => {
                      header.onClick();
                    }}
                  >
                    {header.label}
                    <Image
                      src={
                        sortDirection === 'asc' && sortColumn === header.key
                          ? '/assets/sort-asc.svg'
                          : '/assets/sort-desc.svg'
                      }
                      alt="sort"
                      width={10}
                      height={10}
                      className="ml-[8px] cursor-pointer"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="z-[61] h-[409px] mt-[4px] overflow-y-scroll scrollbar ">
            <div className="grid w-full grid-cols-4 gap-1">
              {sortedItems.map((strategy, index) => {
                const isSelect = selectedStrategyIds?.includes(strategy._id);
                const image =
                  baseAssetImages?.[strategy.defaultBaseToken.toLowerCase()] ??
                  '';

                return (
                  <div
                    className={`${
                      isSelect && 'selected-protocol'
                    } hover:selected-protocol col-start-1 col-end-5 h-[47px] text-white text-[14px] rounded-[8px] grid grid-cols-4 cursor-pointer `}
                    key={`protocol-item-table-${index}`}
                    onClick={() => {
                      GoogleAnalytics.selectStrategyClicked(strategy);
                      if (selectedStrategyIds.includes(strategy._id)) {
                        unsetStrategiesId(strategy._id);
                        PostHogUtils.unselectedStrategy(
                          posthogInstance,
                          strategy,
                          selectedStrategyIds,
                          strategies,
                          'table'
                        );
                      } else {
                        setStrategiesId(strategy._id);
                        PostHogUtils.selectedStrategy(
                          posthogInstance,
                          strategy,
                          selectedStrategyIds,
                          strategies,
                          'table'
                        );
                      }
                    }}
                  >
                    <div className="flex flex-row items-center pl-[17px]">
                      <Image
                        loader={({ src }) => src}
                        src={image}
                        alt="base asset"
                        width={22}
                        height={22}
                        className="h-[22px] w-[22px] relative border-2 border-white rounded-full shadow-md z-10"
                      />
                      <div className="w-[100px] ml-2 font-medium text-white truncate">
                        {strategy.pool}
                      </div>
                    </div>
                    <div className="flex items-center">{strategy.company}</div>
                    <div className="flex items-center">{strategy.network}</div>
                    <div className="flex items-center truncate">
                      {strategy.product}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProtocolsComponent;
