import { Strategy } from '../queries/protocols/types';
import { PostHog } from 'posthog-js';

/* Remember that this information will be used by our marketing team to understand how our users are using the app. So please, be in mind that the event names should be human friendly and easy to understand.

  Some rules to follow:
   - Try to add the event in a chronological order (e.g: if you are adding an event that happens after the user clicks on a button, try to add it after the event that happens when the user clicks on the button)
   - Use static methods only
   - Use camelCase for method names
   - Use snake_case for event properties
   - Use past tense for event names
   - Some event names have a through property. This property is used to understand how the user got to that event when there are different ways to achieve the same action. For example, if the user "selected a strategy" after opening a popup, the event "SelectedStrategy" should have a "through" property equals to "Popup". This way, we can understand how the user got to that event.
*/
export class PostHogUtils {
  static clickedToAddStrategiesPopup(posthogObj: PostHog | undefined) {
    posthogObj?.capture('Clicked to Open Strategies Popup (Mobile)');
  }

  static selectedStrategy(
    posthogObj: PostHog | undefined,
    strategy: Strategy,
    selectedStrategyIds: string[],
    strategies: any,
    through: string
  ) {
    const currentStrategiesName = selectedStrategyIds?.map(
      (selectedStrategyId) => {
        const selectedStrategy = strategies?.strategies?.items.find(
          (strategy: Strategy) => strategy._id === selectedStrategyId
        );
        return `${selectedStrategy?.company} - ${selectedStrategy?.product}`;
      }
    );

    const eventPropertiesObject = {
      through,
      strategy_id: strategy._id,
      strategy_company: strategy?.company,
      strategy_pool: strategy?.pool,
      strategy_product: strategy?.product,
      current_strategies: currentStrategiesName,
    };
    posthogObj?.capture('Selected a strategy', eventPropertiesObject);
  }

  static unselectedStrategy(
    posthogObj: PostHog | undefined,
    strategy: Strategy,
    selectedStrategyIds: string[],
    strategies: any,
    through: string
  ) {
    const currentStrategiesName = selectedStrategyIds?.map(
      (selectedStrategyId) => {
        const selectedStrategy = strategies?.strategies?.items.find(
          (strategy: Strategy) => strategy._id === selectedStrategyId
        );
        return `${selectedStrategy?.company} - ${selectedStrategy?.product}`;
      }
    );
    const eventPropertiesObject = {
      through,
      strategy_id: strategy._id,
      strategy_company: strategy?.company,
      strategy_pool: strategy?.pool,
      strategy_product: strategy?.product,
      current_strategies: currentStrategiesName,
    };
    posthogObj?.capture('Unselected a strategy', eventPropertiesObject);
  }

  static selectedGranularity(posthogObj: PostHog | undefined, value: string) {
    posthogObj?.capture('Selected a granularity', { granularity: value });
  }

  static selectedYieldType(posthogObj: PostHog | undefined, yieldType: string) {
    posthogObj?.capture('Selected Yield Type', { yield_type: yieldType });
  }

  static selectedBaseAssetFilter(
    posthogObj: PostHog | undefined,
    baseAsset: string
  ) {
    posthogObj?.capture('Selected Base Asset', { base_asset: baseAsset });
  }

  static requestedAPIAccess(posthogObj: PostHog | undefined, through: string) {
    posthogObj?.capture('Requested API Access', { through });
  }

  static clickedOnDatePicker(posthogObj: PostHog | undefined, through: string) {
    posthogObj?.capture('Requested API Access', { through });
  }

  static submittedToNewsletter(posthogObj: PostHog | undefined, email: string) {
    posthogObj?.capture('Submitted to Newsletter', { email });
  }

  static submittedToNewsletterSuccess(posthogObj: PostHog | undefined) {
    posthogObj?.capture('Submitted to Newsletter with Success');
  }

  static errorToastClicked(posthogObj: PostHog | undefined, errorMessage: any) {
    posthogObj?.capture('Clicked on Error Toast', {
      error_message: errorMessage,
    });
  }

  static unselectedAllStrategies(posthogObj: PostHog | undefined) {
    posthogObj?.capture('Unselected all strategies');
  }
}
