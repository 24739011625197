import Image from 'next/image';
import React from 'react';
import { FaRegCheckSquare } from 'react-icons/fa';

interface VerifiedByBannerProps {
  started: boolean;
}

// aave, brahma, harvest, instadapp, opyn
const VerifiedBySection: React.FC<VerifiedByBannerProps> = ({ started }) => {
  return (
    <div
      className={`${
        started ? 'opacity-100 translate-y-4' : 'opacity-0'
      } hidden md:flex flex-col mt-[68px] w-full transition-all duration-1000 delay-1000`}
    >
      <div className={`flex flex-row w-full justify-center items-center`}>
        <FaRegCheckSquare className="w-[16px] h-[16px] accent-title bg-background checked:bg-title text-title rounded-[4px]" />
        <div className="font-bold  text-[16px] text-[#9377FF] pl-[10px]">
          Verified By
        </div>
      </div>

      {/* Company logos */}
      <div className="flex flex-row md:gap-[25px] xl:gap-[63px] justify-center mt-[30px]">
        {/* AAVE */}
        <a
          className="flex flex-row justify-center items-center gap-[20px] mt-[20px]"
          target="_blank"
          href="https://aave.com/"
          rel="noreferrer"
        >
          <Image
            src="/assets/aave-icon.svg"
            width={29}
            height={29}
            alt="aave"
          />
          <Image
            src="/assets/aave-text.svg"
            width={85}
            height={23}
            alt="aave"
          />
        </a>

        {/* BRAHMA */}
        <a
          className="flex flex-row justify-center items-center gap-[20px] mt-[20px]"
          target="_blank"
          href="https://www.brahma.fi/"
          rel="noreferrer"
        >
          <Image
            src="/assets/brahma-logo-dark.svg"
            width={146}
            height={41}
            alt="brahma"
          />
        </a>

        {/* HARVEST */}
        <a
          className="flex flex-row justify-center items-center gap-[20px] mt-[20px]"
          target="_blank"
          href="https://www.harvest.finance/"
          rel="noreferrer"
        >
          <Image
            src="/assets/harvest-standard.svg"
            width={146}
            height={41}
            alt="harvest"
          />
        </a>

        {/* INSTADAPP */}
        <a
          className="flex flex-row justify-center items-center gap-[20px] mt-[20px]"
          target="_blank"
          href="https://instadapp.io/"
          rel="noreferrer"
        >
          <Image
            src="/assets/instadapp-logo-dark.svg"
            width={146}
            height={41}
            alt="instadapp"
          />
        </a>

        {/* opyn */}
        <a
          className="flex flex-row justify-center items-center gap-[0px] mt-[20px]"
          target="_blank"
          href="https://www.opyn.co/"
          rel="noreferrer"
        >
          <Image
            src="/assets/opyn-logo.png"
            width={70}
            height={41}
            alt="opyn"
          />
        </a>
      </div>
    </div>
  );
};

export default VerifiedBySection;
