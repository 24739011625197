import api from '../../services/api';
import { PostHogUtils } from '../../utils/PostHog';
import { HistoricalResponse } from './types';
import {
  QueryFunctionContext,
  useQueries,
  useQueryClient,
} from '@tanstack/react-query';
import { usePostHog } from 'posthog-js/react';
import { toast } from 'react-toastify';

async function getHistoricalYield(ctx: QueryFunctionContext) {
  const [, strategy, startDate, currency, endDate, includeLatest] =
    ctx.queryKey;
  const { data } = await api.get<HistoricalResponse[]>(
    `/strategy/${strategy}/history`,
    {
      params: {
        startDate,
        endDate,
        includeLatest,
      },
    }
  );
  return { strategy, data };
}

export function useHistoricalYield(
  strategies: string[],
  startDate: string,
  currency: string,
  endDate?: string | null,
  includeLatest?: boolean | null
) {
  const queryClient = useQueryClient();
  const posthogInstance = usePostHog();
  return useQueries({
    queries: strategies.map((strategy) => {
      return {
        queryKey: [
          'historicalYield',
          strategy,
          startDate,
          currency,
          endDate,
          includeLatest,
        ],
        queryFn: (ctx: QueryFunctionContext) => getHistoricalYield(ctx),
        onError: (error: any) => {
          toast.error(
            'API Fetch error, Click here to try again: \n' + error?.toString(),
            {
              onClick: () => {
                const queryKey = [
                  'historicalYield',
                  strategy,
                  startDate,
                  currency,
                  endDate,
                  includeLatest,
                ];
                PostHogUtils.errorToastClicked(
                  posthogInstance,
                  error?.toString()
                );
                queryClient.refetchQueries(queryKey);
              },
            }
          );
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      };
    }),
  });
}
