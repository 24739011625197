import { useInvestmentsData } from '../../queries/protocols';
import { useProtocolsStore } from '../../stores/protocols';
import { PostHogUtils } from '../../utils/PostHog';
import { DropdownComponent } from '../DropdownComponent';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';

const FilterByBaseAssetComponent: React.FC = () => {
  const { skip, limit, baseAssetFilter, setBaseAssetFilter } =
    useProtocolsStore((state) => ({
      skip: state.skip,
      limit: state.limit,
      baseAssetFilter: state.baseAssetFilter,
      setBaseAssetFilter: state.setBaseAssetFilter,
    }));

  const { data: strategies } = useInvestmentsData(skip, limit);

  const posthogInstance = usePostHog();

  const pool = strategies?.strategies?.items?.map((strategy) => strategy.pool);
  const assets = pool?.filter((element, index) => {
    return pool.indexOf(element) === index;
  });

  const options = assets?.reduce(
    (a, strategy) => (a.push({ label: strategy, value: strategy }), a),
    [{ label: 'All', value: '' }]
  );

  return (
    <DropdownComponent
      title="Base Asset"
      onChange={(e) => {
        setBaseAssetFilter(e);
        PostHogUtils.selectedBaseAssetFilter(posthogInstance, e);
      }}
      className="flex flex-row items-center justify-center w-[167px] h-[35px] p-2 bg-[rgba(209,199,255,0.25)] text-center border-[rgba(255,255,255,0.1)] rounded-full text-white mr-2"
      options={options}
      value={baseAssetFilter}
    />
  );
};

export default FilterByBaseAssetComponent;
