import * as React from 'react';

type Props = {
  message: string;
};
const NoDataFound: React.FC<Props> = ({ message }) => {
  return (
    <div className="flex items-center justify-center h-screen">{message}</div>
  );
};

export default NoDataFound;
