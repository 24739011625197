export const highContrastColors = [
  '#8444A4',
  '#4D6DA4',
  '#4A89A4',
  '#4DA4A4',
  '#4EA489',
  '#52A452',
  '#5CA452',
  '#77A452',
  '#A44D6D',
  '#A45252',
  '#A45A4D',
  '#A4774D',
  '#A4A44D',
  '#6DA44D',
  '#4DA452',
  '#52A4A4',
  '#52A4D1',
  '#525CA4',
  '#7B52A4',
  '#A452A4',
  '#D152A4',
  '#FF52A4',
  '#FF5277',
  '#FF5252',
  '#FF7852',
  '#FFA452',
  '#FFD152',
  '#D1FF52',
  '#7BFF52',
  '#52FFA4',
  '#52D1FF',
  '#5289FF',
  '#5252FF',
  '#8952FF',
  '#D152FF',
  '#FF52D1',
  '#A477A4',
  '#A4A4A4',
  '#FFFFFF',
  '#FFFFAA',
  '#FFD1AA',
  '#FFB6AA',
  '#FF9DAA',
  '#FF7EAA',
  '#FF52FF',
  '#FF52B6',
  '#FF52AA',
  '#FF5289',
  '#FF5277',
  '#FF525C',
  '#FF5252',
  '#FF4D4D',
  '#A4524D',
  '#4D52A4',
  '#4D4DA4',
  '#4D6D7B',
  '#4D8E7B',
  '#4DA48F',
  '#4DA4A4',
  '#4D8E4D',
  '#4D7B6D',
  '#4D7B8E',
  '#4D7BA4',
  '#6D4D7B',
  '#8E4D7B',
  '#A44D7B',
  '#FFD1FF',
  '#D1A4FF',
  '#A477FF',
  '#7B52FF',
  '#4D4DFF',
  '#4D7BD1',
  '#4D8ED1',
  '#4DA4D1',
  '#4D8E52',
  '#4D7B52',
  '#4D6D52',
  '#527B4D',
  '#A44D52',
  '#A44D89',
  '#A44DB6',
];

export function hexToRGB(hex: string, alpha: string) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}
