import api from '../../services/api';
import { StrategiesData } from './types';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

async function investmentsData(ctx: QueryFunctionContext) {
  const [, skip, limit] = ctx.queryKey;
  const { data } = await api.get<StrategiesData>(
    `/homepage?skip=${skip}&limit=${limit}`
  );
  return data;
}

export function useInvestmentsData(skip: string, limit: string = '100') {
  return useQuery(['investmentsData', skip, limit], investmentsData, {
    refetchOnWindowFocus: false,
  });
}

export async function getInvestmentsDataSSR() {
  const skip = '0';
  const limit = '100';
  const { data } = await api.get<StrategiesData>(
    `/homepage?skip=${skip}&limit=${limit}`
  );
  return data;
}
