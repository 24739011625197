import { useRequestAccess } from '../queries/request-access';
import { useModalsStore } from '../stores/modals';
import ApiErrorComponent from './notifications/ApiErrorComponent';
import LoadingComponent from './notifications/LoadingComponent';
import React from 'react';
import { toast } from 'react-toastify';

function RequestAccessDialog() {
  const { showModalRequestAccess, setShowModalRequestAccess } = useModalsStore(
    (state) => ({
      showModalRequestAccess: state.showModalRequestAccess,
      setShowModalRequestAccess: state.setShowModalRequestAccess,
    })
  );

  const [email, setEmail] = React.useState<string>();

  React.useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setShowModalRequestAccess(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const { error, isError, data, isLoading, refetch, isSuccess } =
    useRequestAccess(email);

  React.useEffect(() => {
    if (isSuccess && data) {
      toast('Request sent successfully!', {
        toastId: 'request-api-access',
        type: 'success',
      });
      setShowModalRequestAccess(false);
    }
  }, [data, isSuccess]);

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
    };
    const email = target.email.value;
    setEmail(email);
  }

  return (
    <>
      {showModalRequestAccess ? (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
            onClick={(e) => {
              if (e.target === e.currentTarget)
                setShowModalRequestAccess(false);
            }}
          >
            <div className="relative w-full m-6 max-w-3xl md:w-[420px]">
              {/*content*/}
              <div className="relative flex flex-col w-full h-[300px] border-0 rounded-lg shadow-lg outline-none container-background-dialog focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold text-title">
                    Request Access
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => setShowModalRequestAccess(false)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto">
                  <form onSubmit={handleSubmit}>
                    <div className="p-4 text-body">
                      This feature is still not available. But you can leave
                      your email address to receive a notification when this
                      feature becomes available.
                    </div>
                    <div className="flex flex-col items-center justify-center m-3 mb-6">
                      <div className="flex flex-row items-center justify-center w-full p-1">
                        {isLoading ? (
                          <LoadingComponent message="Sending..." />
                        ) : isError ? (
                          <div className="text-black">
                            <ApiErrorComponent
                              error={error?.toString()}
                              refetch={refetch}
                            />
                          </div>
                        ) : (
                          <div className="relative flex flex-row w-full">
                            <input
                              className="w-full h-[44px] text-[13px] rounded-[44px] bg-input-background text-[#C3BFD9] pl-[16px] pr-[16px] font-medium absolute"
                              placeholder="Email"
                              id="email"
                              name="email"
                              type="email"
                            />
                            <button
                              className="w-[65px] h-[27px] text-[10px] rounded-[44px] border-2 border-[#AF9AFF] text-[#AF9AFF] font-thin absolute right-[7px] top-[9px]"
                              disabled={isLoading}
                              type="submit"
                            >
                              SEND
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                {/*footer*/}
              </div>
            </div>
          </div>
          <div
            className="fixed inset-0 z-40 bg-black opacity-50"
            onClick={() => setShowModalRequestAccess(false)}
          ></div>
        </>
      ) : null}
    </>
  );
}

export default RequestAccessDialog;
