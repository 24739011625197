import { useSubscribeNewsletter } from '../../queries/newsletter';
import { PostHogUtils } from '../../utils/PostHog';
import ApiErrorComponent from '../notifications/ApiErrorComponent';
import LoadingComponent from '../notifications/LoadingComponent';
import Image from 'next/image';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { toast } from 'react-toastify';

const NewsletterComponent: React.FC = () => {
  const [email, setEmail] = React.useState<string>();
  const { data, isLoading, isError, isSuccess, error, refetch } =
    useSubscribeNewsletter(email);
  const posthogInstance = usePostHog();

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
    };
    const email = target.email.value;
    PostHogUtils.submittedToNewsletter(posthogInstance, email);
    setEmail(email);
  }

  React.useEffect(() => {
    if (isSuccess && data) {
      toast('Email subscribed successfully!', {
        toastId: 'subscribe-newsletter',
        type: 'success',
      });
      PostHogUtils.submittedToNewsletterSuccess(posthogInstance);
    }
  }, [isSuccess]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full flex flex-row mt-[21px] relative">
        {isLoading ? (
          <LoadingComponent message="Subscribing..." inline={true} />
        ) : isError ? (
          <div className="text-black">
            <ApiErrorComponent error={error?.toString()} refetch={refetch} />
          </div>
        ) : isSuccess && data ? (
          <div className="flex flex-row items-center w-full mt-2 transition-all duration-200 delay-75 opacity-100">
            <Image
              src="/assets/newsletter-success.svg"
              width={42}
              height={42}
              alt="mailbox"
            />
            <div className="text-[20px] text-[#D8D3ED] font-bold pl-[18px]">
              Thanks for subscribing!
            </div>
          </div>
        ) : (
          <>
            <input
              className="w-full h-[44px] text-[13px] rounded-[44px] bg-input-background text-[#C3BFD9] pl-[16px] pr-[16px] font-medium absolute"
              placeholder="Email"
              id="email"
              name="email"
              type="email"
            />
            <button
              className="w-[85px] h-[27px] text-[10px] rounded-[44px] border-2 border-[#AF9AFF] text-[#AF9AFF] font-thin absolute right-[7px] top-[9px]"
              disabled={isLoading}
              type="submit"
            >
              SUBSCRIBE
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default NewsletterComponent;
