import { create } from 'zustand';

type ProtocolsStore = {
  search: string;
  selectedStrategyIds: string[];
  skip: string;
  limit: string;
  baseAssetFilter: string;
  isProtocolsOpen: boolean;
  filterBy: string;
  setStrategiesId: (id: string) => void;
  unsetStrategiesId: (id: string) => void;
  setSearch: (search: string) => void;
  setBaseAssetFilter: (asset: string) => void;
  unselectAll: () => void;
  setIsProtocolsOpen: (isOpen: boolean) => void;
  setFilterBy: (filterBy: string) => void;
};

export const useProtocolsStore = create<ProtocolsStore>((set) => ({
  selectedStrategyIds: [],
  selectedBaseAssets: [],
  search: '',
  skip: '0',
  limit: '100',
  baseAssetFilter: '',
  isProtocolsOpen: true,
  filterBy: 'all',
  setFilterBy: (filterBy: string) => set({ filterBy }),
  setBaseAssetFilter: (asset: string) => set({ baseAssetFilter: asset }),
  unselectAll: () => set({ selectedStrategyIds: [] }),
  setSearch: (search: string) => set({ search }),
  setIsProtocolsOpen: (isOpen: boolean) => set({ isProtocolsOpen: isOpen }),
  setStrategiesId: (id: string) => {
    if (!id) return;
    set((state) => {
      return {
        selectedStrategyIds: state.selectedStrategyIds.includes(id)
          ? state.selectedStrategyIds
          : [...state.selectedStrategyIds, id],
      };
    });
  },
  unsetStrategiesId: (id: string) => {
    set((state) => ({
      selectedStrategyIds: state.selectedStrategyIds.filter(
        (strategyId) => strategyId !== id
      ),
    }));
  },
}));
