import {
  formatDate,
  formatDateFromString,
  formatYYYYMMDD,
  today1MonthAgo,
} from '../utils/DateUtils';
import { create } from 'zustand';

export type Granularity = '1month' | '6months' | '12months' | 'custom' | 'week';
export type YieldType = 'Yield' | 'Rewards';

export type HistoricalStore = {
  startDate: string;
  startDateFormatted: string;
  endDate: string | null;
  endDateFormatted: string | null;
  granularity: Granularity;
  yieldType: YieldType;
  currency: string;
  setStartDate: (startDate: string) => void;
  setEndDate: (endDate: string | null) => void;
  setGranularity: (granularity: Granularity) => void;
  setYieldType: (yieldType: YieldType) => void;
  setCurrency: (currency: string) => void;
};

export const useHistoricalStore = create<HistoricalStore>((set) => ({
  startDate: formatYYYYMMDD(today1MonthAgo()),
  endDate: null,
  granularity: '1month',
  startDateFormatted: formatDate(today1MonthAgo()),
  endDateFormatted: null,
  yieldType: 'Yield',
  currency: '0x0000000000000000000000000000000000000000',
  setStartDate: (startDate: string) =>
    set(() => ({
      startDate,
      startDateFormatted: formatDateFromString(startDate),
    })),
  setEndDate: (endDate: string | null) =>
    set(() => ({
      endDate: endDate === '' ? null : endDate,
      endDateFormatted:
        endDate === '' ? null : endDate ? formatDateFromString(endDate) : null,
    })),
  setGranularity: (granularity: Granularity) => set(() => ({ granularity })),
  setYieldType: (yieldType: YieldType) => set(() => ({ yieldType })),
  setCurrency: (currency: string) => set(() => ({ currency })),
}));
