import { useInvestmentsData } from '../queries/protocols';
import { useProtocolsStore } from '../stores/protocols';
import { highContrastColors, hexToRGB } from '../utils/Colors';
import { PostHogUtils } from '../utils/PostHog';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';

export const SelectedProtocolsComponent: React.FC = () => {
  const {
    skip,
    limit,
    selectedStrategyIds,
    setStrategiesId,
    unsetStrategiesId,
  } = useProtocolsStore((state) => ({
    skip: state.skip,
    limit: state.limit,
    selectedStrategyIds: state.selectedStrategyIds,
    setStrategiesId: state.setStrategiesId,
    unsetStrategiesId: state.unsetStrategiesId,
  }));

  const { data: strategies } = useInvestmentsData(skip, limit);
  const posthogInstance = usePostHog();

  return (
    <div className="flex flex-row flex-nowrap w-full scrollbar mt-[20px] gap-[10px] xl:items-center overflow-x-auto overflow-y-hidden pb-3">
      {strategies?.strategies?.items.map((strategy, strategyIndex) => {
        const isSelect = selectedStrategyIds?.includes(strategy._id);
        const color = highContrastColors[strategyIndex] ?? '#fff';
        const rgbColor = hexToRGB(color, '0.25');

        if (!isSelect) return null;

        return (
          <div
            key={strategy._id}
            className={`flex flex-row w-auto items-center justify-center p-2 h-[24px] text-center border-[rgba(255,255,255,0.1)] rounded-full `}
            style={{
              backgroundColor: rgbColor,
            }}
          >
            <div
              className={`text-[14px] font-bold mt-[1px]`}
              style={{ color: color, whiteSpace: 'nowrap' }}
            >
              {strategy?.pool + ' - ' + strategy?.company}
            </div>

            <div
              className="w-[12px] ml-[9px] cursor-pointer  "
              onClick={() => {
                if (selectedStrategyIds.includes(strategy._id)) {
                  unsetStrategiesId(strategy._id);
                  PostHogUtils.unselectedStrategy(
                    posthogInstance,
                    strategy,
                    selectedStrategyIds,
                    strategies,
                    'table'
                  );
                } else {
                  setStrategiesId(strategy._id);
                  PostHogUtils.selectedStrategy(
                    posthogInstance,
                    strategy,
                    selectedStrategyIds,
                    strategies,
                    'table'
                  );
                }
              }}
              style={{ color: color }}
            >
              {/* SVG close icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
              >
                <path
                  d="M6.00001 4.58579L9.29292 1.29288C9.68342 0.902377 10.3166 0.902377 10.7071 1.29288C11.0976 1.68338 11.0976 2.31658 10.7071 2.70708L7.4142 6.00001L10.7071 9.29292C11.0976 9.68342 11.0976 10.3166 10.7071 10.7071C10.3166 11.0976 9.68342 11.0976 9.29292 10.7071L6.00001 7.4142L2.7071 10.7071C2.3166 11.0976 1.6834 11.0976 1.2929 10.7071C0.902398 10.3166 0.902398 9.68342 1.2929 9.29292L4.58581 6.00001L1.2929 2.70708C0.902398 2.31658 0.902398 1.68338 1.2929 1.29288C1.6834 0.902377 2.3166 0.902377 2.7071 1.29288L6.00001 4.58579Z"
                  fill={color}
                />
              </svg>
            </div>
          </div>
        );
      })}
    </div>
  );
};
