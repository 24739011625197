import React, { useState } from 'react';

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-600 py-4">
      <button
        onClick={toggleOpen}
        className="w-full text-left flex justify-between items-center"
      >
        <p className="text-[16px] font-bold text-title">{question}</p>
        <span
          className={`transform ${
            isOpen ? 'rotate-[360deg]' : 'rotate-[270deg]'
          } transition-transform duration-300 text-white`}
        >
          ▼
        </span>
      </button>
      <div
        className={`transition-all duration-300 ease-in-out overflow-hidden ${
          isOpen ? 'max-h-[1000px]' : 'max-h-0'
        }`}
      >
        <p className="mt-2 text-[#C3BFD9] font-medium">{answer}</p>
      </div>
    </div>
  );
};

interface FAQData {
  question: string;
  answer: string;
}

interface FAQSectionProps {
  faqData: FAQData[];
}

const FAQSection: React.FC<FAQSectionProps> = ({ faqData }) => {
  return (
    <section className="container mx-auto px-4 py-8 flex flex-col md:justify-center xl:px-[0px] md:mt-[80px] w-full mt-[50px] gap-[20px] xl:gap-[25px]">
      <h2 className="text-2xl font-bold text-white mb-6 pl-[15px]">
        Frequently Asked Questions
      </h2>
      <div className="w-full h-auto container-background rounded-[16px] pl-[27px] pr-[30px] pt-[21px] pb-[25px]">
        {faqData.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </section>
  );
};

export default FAQSection;
