import { useHistoricalYield } from '../queries/historical';
import { StrategiesData } from '../queries/protocols/types';
import { useHistoricalStore } from '../stores/historical';
import { useProtocolsStore } from '../stores/protocols';
import { hexToRGB, highContrastColors } from '../utils/Colors';
import LoadingComponent from './notifications/LoadingComponent';
import { ChartOptions } from 'chart.js';
import 'chartjs-plugin-style';
import React from 'react';
import { Line } from 'react-chartjs-2';

type Props = {
  strategies: StrategiesData | undefined;
};

const LineChart: React.FC<Props> = ({ strategies }) => {
  const { yieldType, startDate, endDate, currency } = useHistoricalStore(
    (state) => ({
      yieldType: state.yieldType,
      startDate: state.startDate,
      endDate: state.endDate,
      currency: state.currency,
    })
  );

  const { selectedStrategyIds } = useProtocolsStore((state) => ({
    selectedStrategyIds: state.selectedStrategyIds,
  }));

  const historicalYieldData = useHistoricalYield(
    selectedStrategyIds,
    startDate,
    currency,
    endDate,
    true
  );
  const isLoading = historicalYieldData.some((result) => result.isLoading);
  if (isLoading) return <LoadingComponent />;

  const graphData = historicalYieldData
    ?.map((value) => {
      if (value?.data) {
        const { strategy, data } = value.data;
        if (data) {
          return {
            _id: strategy as string,
            labels: data?.map((v) => new Date(v.timestamp).valueOf()),
            data: data?.map((v) => {
              if (yieldType === 'Rewards') {
                return (v.position + v.reward - 1) * 100;
              }
              return (v.position - 1) * 100;
            }),
          };
        } else {
          return { _id: '', labels: [], data: [] };
        }
      } else {
        return { _id: '', labels: [], data: [] };
      }
    })
    // sort by the highest value
    .sort((a, b) => {
      if (a.data && b.data) {
        return b.data[b.data.length - 1] - a.data[a.data.length - 1];
      }
      return 0;
    });

  const datasets = graphData.map((dataset) => {
    const strategyIndex =
      strategies?.strategies?.items?.findIndex(
        (obj) => obj._id === dataset._id
      ) ?? 0;
    const strategy =
      strategies?.strategies?.items &&
      strategies?.strategies?.items[strategyIndex];
    const color = highContrastColors[strategyIndex] ?? '#fff';
    let payloadDataset = {
      label:
        strategy?.pool + ' - ' + strategy?.company + ' - ' + strategy?.product,
      fill: false,
      shadowOffsetX: 0,
      shadowOffsetY: 10,
      shadowBlur: 10,
      shadowColor: color,
      backgroundColor: color,
      borderColor: color,
      borderDash: [],
      borderDashOffset: 0.0,
      pointBorderColor: color,
      pointBackgroundColor: color,
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: dataset.data,
    };
    // TODO: bring back the shadows at some point
    if (true) {
      payloadDataset.shadowOffsetX = 0;
      payloadDataset.shadowOffsetY = 0;
      payloadDataset.shadowBlur = 0;
      payloadDataset.shadowColor = 'rgba(0,0,0,0)';
    }
    return payloadDataset;
  });

  const options: ChartOptions = {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    animation: {
      duration: 0, // general animation time
    },
    hover: {
      animationDuration: 0, // duration of animations when hovering an item
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
    tooltips: {
      mode: 'index',
      intersect: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.style.borderRadius = '10px';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem: Chart.ChartTooltipModelBody) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          var titleLines = tooltipModel.title || [];
          var bodyLines = tooltipModel.body.map(getBody);

          var innerHtml = `<div style='font-style: normal; font-weight: 700; font-size: 12px; line-height: 20px; color: #ffffff;'>`;

          titleLines.forEach(function (title) {
            innerHtml += '' + title + '';
          });
          innerHtml += '</div>';
          innerHtml += '<div style="margin-top: 9px;">';

          bodyLines.forEach(function (body, i) {
            var colors = tooltipModel.labelColors[i];
            const rgbColor = hexToRGB(colors.backgroundColor.toString(), '0.4');
            var span = `<div style="display: flex; gap: 5px; margin-top: 14px"><div style="width: 14px; height: 14px; background: ${colors.backgroundColor}; box-shadow: 2px 3px 6px ${rgbColor}; border-radius: 3px;"></div>`;
            innerHtml +=
              span + '<div style="color: white">' + body + '</div>' + '</div>';
          });
          const tooltip = document.getElementById('chartjs-tooltip');
          if (tooltip) {
            tooltip.innerHTML = innerHtml;
          }
        }

        // `this` will be the overall tooltip
        var position = (this as any)._chart.canvas.getBoundingClientRect();

        let positionX = 0;
        const horizontalOffset = 40;
        if (
          position.left +
            window.pageXOffset +
            tooltipModel.caretX +
            (tooltipModel.width + 2 * horizontalOffset) >
          position.right
        ) {
          positionX =
            position.left +
            window.pageXOffset +
            tooltipModel.caretX -
            (tooltipModel.width / 2 + horizontalOffset);
        } else {
          positionX =
            position.left +
            window.pageXOffset +
            tooltipModel.caretX +
            (tooltipModel.width / 2 + horizontalOffset);
        }

        if (positionX < tooltipModel.width / 2) {
          positionX =
            position.left + (tooltipModel.width / 2 + horizontalOffset);
        }

        // Mobile offset problem
        const offsetY =
          (window.event as MouseEvent).clientY ??
          (window.event as TouchEvent).touches[0].clientY;
        const positionY = window.pageYOffset + offsetY + 10;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = positionX + 'px';
        tooltipEl.style.top = positionY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.borderRadius = '10px';
        tooltipEl.style.padding = '13px';
        tooltipEl.style.pointerEvents = 'none';
      },
      enabled: false,
      callbacks: {
        label: function (tooltipItem, data) {
          let label =
            data.datasets?.[tooltipItem.datasetIndex || 0].label || '';

          if (label) {
            label += ': ';
          }
          label +=
            parseFloat((tooltipItem.yLabel || 0).toString()).toFixed(2) + '%';
          return label;
        },
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          distribution: 'linear',
          scaleLabel: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: function (value: number) {
              return parseFloat(value.toString()).toFixed(2) + '%';
            },
          },
        },
      ],
    },
  };

  const data = {
    labels: graphData[0] ? graphData[0].labels : [],
    datasets,
  };
  return <Line data={data} height={470} options={options} redraw />;
};

export default LineChart;
