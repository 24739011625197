import { Strategy } from '../queries/protocols/types';
import { event } from 'nextjs-google-analytics';
import * as React from 'react';

export class GoogleAnalytics {
  static downloadCSVClicked() {
    event('download_csv', {
      category: 'Fake_Doors',
      action: 'download_csv',
    });
  }

  static granularityBlocklyClicked() {
    event('granularity_blockly', {
      category: 'Fake_Doors',
      action: 'granularity_blockly',
    });
  }

  static granularityClicked(granularity: string) {
    event('granularity_clicked', {
      category: 'General',
      action: 'granularity_clicked',
      label: granularity,
    });
  }

  static askNewProjectClicked() {
    event('ask_new_project', {
      category: 'Fake_Doors',
      action: 'ask_new_project',
    });
  }

  static requestApiAccessClicked() {
    event('request_api_access', {
      category: 'Fake_Doors',
      action: 'request_api_access',
    });
  }

  static selectStrategyClicked(strategy: Strategy) {
    event('select_strategy', {
      category: 'General',
      action: 'select_strategy',
      label: strategy._id,
    });
  }
}
