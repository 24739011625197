import { create } from 'zustand';

export type ModalsStore = {
  showModalRequestAccess: boolean;
  showModalDatePicker: boolean;
  showModalProtocolsMobile: boolean;
  setShowModalDatePicker: (showModalDatePicker: boolean) => void;
  setShowModalProtocolsMobile: (showModalProtocolsMobile: boolean) => void;
  setShowModalRequestAccess: (showModalRequestAccess: boolean) => void;
};

export const useModalsStore = create<ModalsStore>((set) => ({
  showModalDatePicker: false,
  showModalProtocolsMobile: false,
  showModalRequestAccess: false,
  setShowModalProtocolsMobile: (showModalProtocolsMobile: boolean) =>
    set(() => ({ showModalProtocolsMobile })),
  setShowModalDatePicker: (showModalDatePicker: boolean) =>
    set(() => ({ showModalDatePicker })),
  setShowModalRequestAccess: (showModalRequestAccess: boolean) =>
    set(() => ({ showModalRequestAccess })),
}));
