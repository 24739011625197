import * as React from 'react';

type Props = {
  error?: string;
  refetch: () => void;
};

const ApiErrorComponent: React.FC<Props> = ({ error, refetch }) => {
  React.useEffect(() => {
    console.log('error', error);
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="text-2xl font-bold">Oops... something went wrong</div>
      <div className="text-lg font-bold">{error}</div>
      <button
        className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded"
        onClick={() => {
          refetch();
        }}
      >
        Try again
      </button>
    </div>
  );
};

export default ApiErrorComponent;
