import { DropdownComponent } from '../components/DropdownComponent';
import FAQSection from '../components/FAQ';
import LineChart from '../components/LineChart';
import ProtocolsComponent from '../components/ProtocolsComponent';
import ProtocolsComponentDialog from '../components/ProtocolsComponentDialog';
import RequestAccessDialog from '../components/RequestAccessDialog';
import SelectStartEndDateDialog from '../components/SelectStartEndDateDialog';
import { SelectedProtocolsComponent } from '../components/SelectedProtocolsComponent';
import VerifiedBySection from '../components/VerifiedBySection';
import Footer from '../components/navigation/Footer';
import NewsletterComponent from '../components/navigation/NewsletterComponent';
import NoDataFound from '../components/notifications/NoDataFound';
import { getInvestmentsDataSSR } from '../queries/protocols';
import { StrategiesData } from '../queries/protocols/types';
import {
  Granularity,
  YieldType,
  useHistoricalStore,
} from '../stores/historical';
import { useModalsStore } from '../stores/modals';
import { useProtocolsStore } from '../stores/protocols';
import { PostHogUtils } from '../utils/PostHog';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import React from 'react';

export async function getStaticProps() {
  try {
    const investments = await getInvestmentsDataSSR();
    return {
      props: {
        strategies: investments,
      },
      revalidate: 60,
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
    };
  }
}
interface FAQData {
  question: string;
  answer: string;
}

const Home: React.FC<{ strategies: StrategiesData }> = ({ strategies }) => {
  const [started, setStarted] = React.useState(false);
  const router = useRouter();
  const {
    granularity,
    setGranularity,
    yieldType,
    setYieldType,
    startDateFormatted,
    endDateFormatted,
    setEndDate,
    setStartDate,
  } = useHistoricalStore((state) => ({
    granularity: state.granularity,
    setGranularity: state.setGranularity,
    yieldType: state.yieldType,
    setYieldType: state.setYieldType,
    startDateFormatted: state.startDateFormatted,
    endDateFormatted: state.endDateFormatted,
    setEndDate: state.setEndDate,
    setStartDate: state.setStartDate,
  }));

  const {
    setShowModalDatePicker,
    setShowModalProtocolsMobile,
    setShowModalRequestAccess,
  } = useModalsStore((state) => ({
    setShowModalDatePicker: state.setShowModalDatePicker,
    setShowModalProtocolsMobile: state.setShowModalProtocolsMobile,
    setShowModalRequestAccess: state.setShowModalRequestAccess,
  }));

  const {
    setStrategiesId,
    selectedStrategyIds,
    isProtocolsOpen,
    setIsProtocolsOpen,
  } = useProtocolsStore((state) => ({
    setStrategiesId: state.setStrategiesId,
    selectedStrategyIds: state.selectedStrategyIds,
    isProtocolsOpen: state.isProtocolsOpen,
    setIsProtocolsOpen: state.setIsProtocolsOpen,
  }));

  const posthogInstance = usePostHog();

  // Set initial selected strategy PODS
  React.useEffect(() => {
    if (strategies && strategies.strategies.items.length > 0) {
      setStrategiesId(
        strategies.strategies.items.find((obj) => obj.company === 'Pods')
          ?._id ?? ''
      );
    }
  }, [strategies, setStrategiesId, strategies.strategies.items]);

  // everytime the user select/deselect strategy, update the URL
  React.useEffect(() => {
    if (!started) return;
    router.push({ query: { protocols: selectedStrategyIds } }, undefined, {
      shallow: true,
    });
  }, [selectedStrategyIds]);

  // when the user access the page, it get the strategies from URL and update the state
  React.useEffect(() => {
    if (!started) return;
    const { protocols = [] } = router.query;
    const updatedSelectedStrategyIds = Array.isArray(protocols)
      ? protocols
      : [protocols];

    // if the strategy from the URL doesn't exist, remove that from the list
    const strategyIds = strategies.strategies.items
      .map((strategy) => strategy._id)
      .filter((id) => updatedSelectedStrategyIds.includes(id));

    // update the strategies on the state
    strategyIds.forEach((id) => {
      setStrategiesId(id);
    });
  }, [started, router.query]);

  React.useEffect(() => {
    setStarted(true);
  }, []);

  // function handleGranularityChange(e: React.ChangeEvent<HTMLSelectElement>) {
  function handleGranularityChange(value: Granularity) {
    // const value = e.target.value as Granularity;
    setGranularity(value);
    if (value === 'custom') {
      setShowModalDatePicker(true);
      PostHogUtils.clickedOnDatePicker(posthogInstance, 'dropdown custom');
      return;
    }
    const dateYYYYMMDD = new Date().toISOString().split('T')[0];
    setEndDate(dateYYYYMMDD);
    const dates: Record<Granularity, number> = {
      '1month': 1,
      '6months': 6,
      '12months': 12,
      custom: 0,
      week: 0,
    };
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - dates[value]);
    setStartDate(startDate.toISOString().split('T')[0]);
    PostHogUtils.selectedGranularity(posthogInstance, value);
  }

  function handleRankingStrategyClick(id: string) {
    setStrategiesId(id);
    const offsetTop = document.getElementById('graph')?.offsetTop;
    window.scroll({
      top: offsetTop,
      behavior: 'smooth',
    });
  }

  const timeRange =
    startDateFormatted +
    ' ' +
    (endDateFormatted ? 'to ' + endDateFormatted : ' until now');

  const faqData: FAQData[] = [
    {
      question: 'Where does the data come from?',
      answer:
        'All data displayed on DeFi Returns is sourced from the blockchain, except for the price data, which is obtained from DeFi Llama.',
    },
    {
      question: 'How do you calculate the returns?',
      answer:
        'We use custom integrations for each protocol, but they all follow the same principle: we calculate the value of a position for each day of the selected date range. For single-token strategies, we divide the current position by the initial position to determine the yield. For multi-token strategies, we divide the current position by the current value of the initial position, effectively comparing the strategy to a buy-and-hold strategy of the initial assets. This is sometimes referred to as Impermanent Loss.',
    },
    {
      question: 'What about strategies with rewards?',
      answer:
        "For strategies with rewards, you can choose to view the accumulated yield with or without rewards by clicking on the 'Yield' dropdown in the top right corner of the chart. The system calculates the number of reward tokens one would receive for an initial position and converts them to the Base Asset, allowing it to be added to the principal position.",
    },
    {
      question: 'Is there a cost to use DeFi Returns?',
      answer:
        'DeFi Returns as you see here is a free to access, but we have a paid API with smaller granularity. Our goal is to provide users with valuable insights and data about various DeFi strategies and protocols.',
    },
    {
      question: 'How can I suggest a new feature or report a bug?',
      answer:
        'We appreciate your feedback and suggestions. Please reach out to us through our discord or email us at defireturns@pods.finance to report bugs or suggest new features.',
    },
    {
      question: 'How can I add my project to DeFi Returns?',
      answer:
        'If you have a DeFi project you would like to see listed on DeFi Returns, please get in touch with us through our discord or email us at defireturns@pods.finance with the relevant information about your project.',
    },
    {
      question: 'Do you support different blockchain networks?',
      answer:
        'Yes, DeFi Returns supports data from multiple blockchain networks, including Ethereum, Polygon, Arbitrum, Optimism, and others. We are constantly working to expand our coverage and include more networks and protocols.',
    },
    {
      question: 'What is the purpose of DeFi Returns?',
      answer:
        'DeFi Returns aims to bring transparency to DeFi and provide users with comprehensive, reliable and up-to-date information on various DeFi strategies and protocols. Our platform allows users to easily compare and analyze the performance of different strategies, bringing the most reliable data source for historical yield on DeFi, helping them make more informed decisions when investing in the ecosystem.',
    },
  ];

  return (
    <div className="bg-background md:flex md:flex-col md:justify-center md:items-center md:w-full">
      <div className="flex flex-col min-h-screen items-center md:mt-[36px] xl:p-0 p-[16px] md:w-full lg:max-w-[1281px]">
        {/* Header */}
        {/* Logo */}
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-row items-end content-end xl:w-full text-end">
            <Image src="/assets/logo.svg" width={40} height={40} alt="logo" />
            <div className="text-[17px] md:text-[25px] md:relative md:bottom-[-9px] text-white font-extrabold ml-[16px]">
              DeFi Returns
            </div>
          </div>

          <button
            className="w-[167px] pt-1 h-[43px] text-[14px] mt-0 xl:mt-[20px] border-2 border-[#AF9AFF] text-[#AF9AFF] rounded-[43px]  font-semibold text-center z-2 cursor-pointer hover:opacity-50"
            onClick={() => {
              setShowModalRequestAccess(true);
              PostHogUtils.requestedAPIAccess(posthogInstance, 'header');
            }}
          >
            GET API ACCESS
          </button>
        </div>

        {/* Hero Text */}
        <div
          className={`${
            started ? 'opacity-100 translate-y-4' : 'opacity-0'
          }  text-[30px] xl:w-[1013px] md:text-[50px] md:text-[transparent] text-white font-[700] mt-[80px] ease-in text-center transition-opacity duration-1000 delay-75 hero-text`}
        >
          Compare historical returns to maximize your earning potential
        </div>

        {/*  */}
        {/* Verified By */}
        {/*  */}

        <VerifiedBySection started={started} />

        {/* Summary of returns */}
        <div className="flex flex-col md:flex-row md:justify-center xl:px-[0px] md:mt-[80px] w-full mt-[50px] gap-[20px] xl:gap-[25px]">
          {/* Box */}
          <div
            className={`${
              started ? 'opacity-100 translate-y-4' : 'opacity-0'
            } w-full h-[180px] xl:w-[345px] container-background rounded-[16px] pl-[27px] pr-[30px] pt-[21px] pb-[25px] transition-opacity duration-1000 delay-[1200ms]`}
          >
            {/* Title */}
            <div className="flex flex-row w-full">
              {/* icon */}
              <Image
                src="/assets/ethereum.svg"
                width={16}
                height={16}
                alt="ether realized APY"
              />
              <div
                className="text-[16px] font-bold text-title ml-[8px] truncate"
                title="Ether Realized APY 30d"
              >
                Ether Realized APY 30d
              </div>
            </div>
            {/* Content */}
            <div className="w-full flex flex-col gap-[10px] mt-[19px]">
              {/* row */}
              {strategies?.topEthStrategies
                // remove undefined
                .filter((x) => x)
                .map((ethStrategy, index) => {
                  return (
                    <div
                      className="flex flex-row content-between w-full cursor-pointer"
                      key={'eth-top-' + index}
                      onClick={() => {
                        handleRankingStrategyClick(ethStrategy._id);
                      }}
                    >
                      <div className="w-full gap-[10px] flex flex-row">
                        <div className="text-[14px] text-white font-bold truncate">
                          {ethStrategy.pool}
                        </div>
                        <div className="text-[14px] text-[#C3BFD9] font-bold">
                          {ethStrategy.company}
                        </div>
                        <div className="text-[14px] text-[#C3BFD9] font-medium">
                          {ethStrategy.network}
                        </div>
                      </div>
                      <div className="returns">
                        <div className="text-[14px] text-[#AF9AFF] font-semibold">
                          {ethStrategy.apy.toFixed(2)}%
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* Box Stablecoins */}
          <div
            className={`${
              started ? 'opacity-100 translate-y-4' : 'opacity-0'
            } w-full h-[180px] xl:w-[345px] container-background  rounded-[16px] pl-[27px] pr-[30px] pt-[21px] pb-[25px] transition-opacity duration-1000 delay-[1400ms]`}
          >
            {/* Title */}
            <div className="flex flex-row w-full">
              {/* icon */}
              <Image
                src="/assets/coin-stacks.svg"
                width={16}
                height={16}
                alt="Stablecoins realized APY"
              />
              <div
                className="text-[16px] font-bold text-title ml-[8px] truncate"
                title="Stablecoins realized APY 30d"
              >
                Stablecoins realized APY 30d
              </div>
            </div>
            {/* Content */}
            <div className="w-full flex flex-col gap-[10px] mt-[19px]">
              {/* row */}
              {strategies?.topStableStrategies
                // remove undefined
                .filter((x) => x)
                .map((stablecoin, index) => {
                  return (
                    <div
                      className="flex flex-row content-between w-full cursor-pointer"
                      key={'stablecoin-top-' + index}
                      onClick={() => {
                        handleRankingStrategyClick(stablecoin._id);
                      }}
                    >
                      <div className="w-full gap-[10px] flex flex-row">
                        <div className="text-[14px] text-white font-bold truncate">
                          {stablecoin.pool}
                        </div>
                        <div className="text-[14px] text-[#C3BFD9] font-bold">
                          {stablecoin.company}
                        </div>
                        <div className="text-[14px] text-[#C3BFD9] font-medium">
                          {stablecoin.network}
                        </div>
                      </div>
                      <div className="returns">
                        <div className="text-[14px] text-[#AF9AFF] font-semibold">
                          {stablecoin.apy.toFixed(2)}%
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* Newsletter Box */}
          <div
            className={`${
              started ? 'opacity-100 translate-y-4' : 'opacity-0'
            } w-full h-[180px] xl:w-[529px] container-background  rounded-[16px] pl-[25px] pr-[30px] pt-[21px] pb-[25px] transition-opacity duration-1000 delay-[1600ms]`}
          >
            {/* Title */}
            <div className="flex flex-row w-full">
              {/* icon */}
              <Image
                src="/assets/newsletter.svg"
                width={16}
                height={16}
                alt="Get our Newsletter"
              />
              <div className="text-[16px] font-bold text-title ml-[8px]">
                Get our Newsletter
              </div>
            </div>
            {/* Content */}
            <div className="flex mt-[7px] font-medium text-[#C3BFD9]">
              Stay tune with the best opportunities
            </div>
            {/* Input with button on the right side inside the input*/}
            <NewsletterComponent />
          </div>
        </div>

        {/*  */}
        {/* Historical Returns */}
        {/*  */}
        <div
          id="graph"
          className={`${
            started ? 'opacity-100 translate-y-4' : 'opacity-0'
          } flex flex-row w-full transition-opacity duration-1000 delay-[1800ms] relative md:h-[826px]`}
        >
          {/* Protocols Table */}
          {strategies.strategies.count > 0 ? (
            <>
              <div
                className={`${
                  !isProtocolsOpen ? 'md:z-10 shadow-protocols' : 'md:z-0'
                }
              hidden xl:flex xl:w-[536px] xl:left-0 xl:h-[auto] xl:mt-[85px] transition-all duration-500 xl:absolute 
            `}
              >
                <ProtocolsComponent items={strategies?.strategies?.items} />
              </div>
              {!isProtocolsOpen && (
                <div
                  id="show-button"
                  className="text-[16px] text-title hidden xl:block cursor-pointer md:pl-4 md:z-[9999] absolute top-0 left-[174px] xl:mt-[93px]"
                  onClick={() => setIsProtocolsOpen(true)}
                >
                  Show {'>'}
                </div>
              )}
            </>
          ) : (
            <NoDataFound message="No protocols found." />
          )}
          {/* Chart */}
          <div
            className={`${
              isProtocolsOpen ? 'xl:w-[718px]' : 'xl:w-[1028px]'
            } w-full flex flex-col mt-[85px] md:absolute md:z-[12] md:right-0 transition-all`}
          >
            <div className="md:pl-4">
              {/* Title */}
              <div className="text-[25px] font-bold text-white">
                Historical Returns
              </div>
              {/* Description */}
              <div className="text-[#C3BFD9] font-medium text-[14px] mt-[7px] md:hidden">
                Realized and historical returns are now available for your
                favorite DeFi strategy. Select the strategies and timeframes you
                want to compare.
              </div>
              <div className="text-[#C3BFD9] font-medium text-[14px] mt-[14px] hidden md:block">
                Realized and historical returns are now available for your
                favorite DeFi strategy.
              </div>
            </div>
            {/* Button add assets */}
            <div className="xl:hidden">
              <div className="w-full flex flex-row mt-[21px] gap-[10px] justify-center">
                <button
                  className="w-[236px] h-[42px] text-[14px] text-white rounded-[43px] bg-add-assets-button font-semibold flex justify-center items-center"
                  onClick={() => {
                    setShowModalProtocolsMobile(true);
                    PostHogUtils.clickedToAddStrategiesPopup(posthogInstance);
                  }}
                >
                  ADD ASSETS
                </button>
              </div>
            </div>
            {/* Graph */}
            <div className="w-full md:h-[594px] container-background-graph-expanded rounded-[16px] mt-[17px] p-[16px] flex flex-col ">
              {/* title */}
              <div className="flex flex-col md:flex-row md:justify-between">
                {/* <div className="flex flex-row justify-center w-full"> */}
                <div
                  className="flex flex-row text-[15px] font-bold text-[#AF9AFF] cursor-pointer"
                  onClick={() => {
                    setShowModalDatePicker(true);
                    PostHogUtils.clickedOnDatePicker(
                      posthogInstance,
                      'Results for'
                    );
                  }}
                >
                  Results for {timeRange}
                </div>

                <div className="flex flex-row mt-[20px] md:mt-0">
                  <DropdownComponent
                    className="flex flex-row text-center p-4 justify-center items-center md:w-[170px] h-[39px] bg-[rgba(209,199,255,0.25)] border-[rgba(255,255,255,0.1)] rounded-full text-white mr-2"
                    options={[
                      {
                        value: 'Yield',
                        label: 'Yield',
                      },
                      {
                        value: 'Rewards',
                        label: 'Yield + Rewards',
                      },
                    ]}
                    onChange={(e) => {
                      setYieldType(e as YieldType);
                      PostHogUtils.selectedYieldType(
                        posthogInstance,
                        e as YieldType
                      );
                    }}
                    value={yieldType}
                  />
                  <DropdownComponent
                    className="flex flex-row items-center justify-center md:w-[125px] h-[39px] bg-[rgba(209,199,255,0.25)] text-center border-[rgba(255,255,255,0.1)] rounded-full text-white md:mr-2 p-4"
                    options={[
                      {
                        value: '1month',
                        label: '1 Month',
                      },
                      {
                        value: '6months',
                        label: '6 Months',
                      },
                      {
                        value: '12months',
                        label: '12 Months',
                      },
                      {
                        value: 'custom',
                        label: 'Custom',
                      },
                    ]}
                    onChange={(e) => {
                      handleGranularityChange(e as Granularity);
                    }}
                    value={granularity}
                  />
                </div>
              </div>
              {/* LineChart */}
              <div className="hidden w-full pb-4 md:block">
                <SelectedProtocolsComponent />
              </div>
              <div className="h-[500px] mt-[6px]">
                <LineChart strategies={strategies} />
              </div>
              {/* Clear selection */}
              {selectedStrategyIds.length > 0 && (
                <div className="flex flex-row justify-end text-[12px] font-bold text-[#AF9AFF] mt-[22px] md:hidden">
                  Clear selections
                </div>
              )}
              {/*  */}
              {/* flex wrap of selected protocls */}
              {/*  */}
              <div className="w-full md:hidden">
                <SelectedProtocolsComponent />
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        {/* FAQ */}
        {/*  */}

        <div
          className={`${
            started ? 'opacity-100 translate-y-4' : 'opacity-0'
          } transition-opacity duration-1000 delay-[2000ms]`}
        >
          <FAQSection faqData={faqData} />
        </div>

        {/*  */}
        {/* Full Access to API */}
        {/*  */}

        <div className="w-full h-[293px] md:h-[354px] rounded-[16px] md:bg-full-access-api-desktop bg-full-access-api flex flex-col mt-[33px] pt-[57px] px-[50px] md:px-[77px] ">
          <div className="text-white text-[28px] md:w-[414px] md:text-[42px] font-semibold z-2">
            Get full Access to our API
          </div>
          <button
            className="w-[141px] pt-1 h-[43px] text-[14px] mt-[30px] border-2 border-[#AF9AFF] text-[#AF9AFF] rounded-[43px]  font-semibold text-center z-2 cursor-pointer hover:opacity-50"
            onClick={() => {
              setShowModalRequestAccess(true);
              PostHogUtils.requestedAPIAccess(posthogInstance, 'contact us');
            }}
          >
            CONTACT US
          </button>
        </div>

        {/* Date Picker */}
        <SelectStartEndDateDialog />

        {/* Protocols for mobile */}
        <ProtocolsComponentDialog />

        {/* Request acess modal */}
        <RequestAccessDialog />
      </div>

      <Footer />
    </div>
  );
};

export default Home;
