import Image from 'next/image';
import {
  FaDiscord,
  FaGithub,
  FaMedium,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="bg-[#1F1C30] p-4 mt-[48px] w-full">
      <a
        href="https://www.pods.finance/"
        target="_blank"
        rel="noreferrer"
        className="flex flex-row justify-center py-4"
      >
        <div className="flex flex-row items-center justify-center w-full">
          <Image src="/assets/logo.svg" width={34} height={34} alt="logo" />
          <div className="text-[17px] text-white font-extrabold ml-[16px] relative bottom-[-8px]">
            Defi Returns
          </div>
        </div>
      </a>
      <div className="flex flex-row justify-center items-center mt-[36px]">
        <a
          href="/assets/disclaimer.pdf"
          target="_blank"
          rel="noreferrer"
          className="py-1 ml-4 text-white hover:underline"
        >
          Disclaimer
        </a>
        <div className="ml-4 border-r-[1px] border-white h-[16px]"></div>
        <a
          href="/assets/privacy-policy.pdf"
          target={'_blank'}
          rel="noreferrer"
          className="py-1 ml-4 text-white hover:underline"
        >
          Privacy Policy
        </a>
      </div>
      <div className="container mx-auto gap-2 flex flex-wrap md:flex-row items-center justify-center p-4 mt-[52px]">
        <a
          href="https://twitter.com/DefiReturns"
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center justify-center text-white md:flex-row hover:text-gray-500"
        >
          <FaTwitter className="mr-2 w-[25px] h-[25px]" />
          <div className="hidden md:block text-[#C3BFD9]">Twitter</div>
        </a>
        <a
          href="https://github.com/pods-finance"
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center justify-center ml-4 text-white md:flex-row hover:text-gray-500 md:ml-8"
        >
          <FaGithub className="mr-2 w-[25px] h-[25px]" />
          <div className="hidden md:block text-[#C3BFD9]">Github</div>
        </a>
        <a
          href="https://discord.com/invite/Qf7utym"
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center justify-center ml-4 text-white md:flex-row hover:text-gray-500 md:ml-8"
        >
          <FaDiscord className="mr-2 w-[25px] h-[25px]" />
          <div className="hidden md:block text-[#C3BFD9]">Discord</div>
        </a>
        <a
          href="https://www.youtube.com/c/PodsFinance"
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center justify-center ml-4 text-white md:flex-row hover:text-gray-500 md:ml-8"
        >
          <FaYoutube className="mr-2 w-[25px] h-[25px]" />
          <div className="hidden md:block text-[#C3BFD9]">Youtube</div>
        </a>
        <a
          href="https://blog.pods.finance/"
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center justify-center ml-4 text-white md:flex-row hover:text-gray-500 md:ml-8"
        >
          <FaMedium className="mr-2 w-[25px] h-[25px]" />
          <div className="hidden md:block text-[#C3BFD9]">Medium</div>
        </a>
      </div>
    </div>
  );
};

export default Footer;
